<template>
  <div class="enjoy">
    <img
      class="iconimg"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/HaiNan/foodCouponMember/top.jpg"
    />
    <div class="form">
      <img
        class="one"
        v-show="num == 1"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/HaiNan/5GPremiumPackage/stepFinger1.gif"
      />
      <van-field
        v-model="form.phone"
        type="tel"
        maxlength="11"
        placeholder="请输入您的手机号码"
        @input="changePhone"
      />
      <img
        class="two"
        v-show="num == 2"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/HaiNan/5GPremiumPackage/stepFinger2.gif"
      />
      <img
        class="three"
        v-show="num == 3"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/HaiNan/5GPremiumPackage/stepFinger3.gif"
      />
      <img
        class="four"
        v-show="num == 4"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/HaiNan/5GPremiumPackage/stepFinger4.gif"
      />
      <template>
        <van-field
          v-model="form.code"
          type="number"
          center
          maxlength="6"
          autocomplete="one-time-code"
          placeholder="请输入短信验证码"
          :class="['input-code', { 'two-cell': num == 2 }]"
        >
          <template slot="right-icon">
            <div>
              <div class="code" v-show="sendAuthCode" @click="getAuthCode">
                获取验证码
              </div>
            </div>
            <div class="code" v-show="!sendAuthCode">
              <span>{{ auth_time }}秒</span>重新获取
            </div>
          </template>
        </van-field>
      </template>
      <img
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/HaiNan/foodCouponMember/submit.png"
        class="submit"
        @click="onSubmit"
      />

      <div class="checked">
        <van-checkbox
          checked-color="#ff850f"
          v-model="checked"
          icon-size="16px"
        >
          <div class="checked-label">
            我已阅读并同意<span class="book" @click="showModal1"
              >《隐私条款》</span
            ><span class="book" @click="showModal2">《受理协议》</span>
          </div>
        </van-checkbox>
        <div class="kefu">客服热线：4001189599</div>
      </div>
    </div>
    <img
      class="iconimg"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/HaiNan/foodCouponMember/equity.jpg"
    />
    <div class="info">
      <div class="info-title">活动规则</div>
      <pre class="info-text">
1、<span>产品资费19.9元/⽉(订购当⽇⼀次性扣除，续订⽉按⾃然⽉份续订，在每⽉初进⾏扣费)</span>
2、产品使⽤
（1）订购后每⽉可享受1张20元饭票券、权益四选⼀(爱奇艺⻩⾦会员⽉卡/优酷VIP会员⽉卡/腾讯视频VIP⽉卡/20元饭票券*1张)、10GB/⽉视频、⾳乐类共享定向流量(抖⾳、快⼿、腾讯视频、爱奇艺、优酷、咪咕视频、QQ⾳乐、酷狗⾳乐、咪咕⾳乐、喜⻢拉雅FM)，订购后⽴即⽣效，次⽉⾃动续订，权益⽆需客户领取，⾃动发放⾄账户中
（2）若⽤户当⽉退订海南移动饭票联名会员，会员将在次⽉⽣效;且赠送的定向流量也将在次⽉失效。
3、定向流量说明
（1）定向流量优先于套餐通⽤流量;使⽤定向流量时优先使⽤本次产品的定向流量，单系定向流量的使⽤优先于多系共享的定向流量产品。
（2）<span>本产品定向流量不结转、不能与他⼈共享、不能转赠，不可回溯办理前已使⽤流量。
（3）定向流量适⽤范围:建议使⽤定向流量包前将APP升级⾄最新版本，请在WIFI功能关闭下使⽤定向流量功能，避免WIFI和移动⽹络频繁切换导致定向流量服务启动失败。客户端不包含在“定向流量“内的适⽤范围，特别说明如下:
①客户端启动、登录;客户端内的插播⼴告(图⽚、⽂字及视频)、内嵌H5⻚⾯、弹幕，第三⽅的⼴告，直播或主播类视频，K歌功能，语⾳及视频聊天，在线观看和下载缓存的第三⽅视频，下载视频等。
②⼿机号码处于国际或港澳台地区漫游状态下产⽣的流量。
③利⽤⼿机设置代理服务器或VPN等⽅式，访问APP所产⽣的流量。
④⽤⽆线上⽹卡、平板电脑、CPE、MIFI等⾮⼿机设备使⽤产⽣的流量。
⑤使⽤CMWAP接⼊点⽅式，访问视频APP所产⽣的流量。
⑥当号码作为热点使⽤时，⽹络不稳定可能会出现定向流量和通⽤流量交错使⽤的情况。
（4）产品包含的定向流量仍遵循客户基础套餐的封顶、限速规则，详询10086。</span>
4、权益发放与领取
（1）发放时间:产品⽣效期间，每⾃然⽉1⽇将⾃动发放1张20元饭票券⾄客户账户中，过期未使⽤导致失效则次⽉不做补发。
（2）发放权益:1张20元饭票券。
（3）领取时间:产品⽣效期间，每⾃然⽉1⽇⾄末⽇起均可领取权益四选⼀内容。
（4）领取权益:爱奇艺⻩⾦会员⽉卡/优酷VIP会员⽉卡/腾讯视频VIP⽉卡/20元饭票券*1张(四选⼀)。
（5）有效期是⽣效⽇期⾄次⽉同⽇，例如:3⽉5⽇⽣效的⽉代⾦券，有效期⾄4⽉4⽇。
5、查看⼊⼝:
（1）20元饭票券：每⽉⾃动下发，到账后30天内（含当⽇）有效，需使⽤订购⼿机号码登录【和圈圈-微信公众号-圈圈中⼼-权益记录】查看权益。
（2）爱奇艺⻩⾦会员⽉卡/优酷VIP会员⽉卡/腾讯视频VIP⽉卡:权益领取成功后，官⽅直充到订购的⼿机号中(⽆法更改充值账户)，预计1-5分钟内⾃动到账。请⽤会员本⼈⼿机号码登录对应APP查看到账情况，具体使⽤有效期以官⽅展示为准。
6、使⽤说明:使⽤⽅式:点击饭票券-⽴即兑换-权益领取成功后，饭票将⾃动发放⾄同⼀⼿机号所在的账户中;权益四选⼀的视频会员在活动⻚⾯点击领取后，将充值到对应⼿机号中，下载对应APP即可查看使⽤。特殊说明:权益领取后⽆法退换，饭票不找零，不兑现有效期⾃领取之⽇起30*24⼩时内有效，逾期视为⾃动放弃权益。⽤户可进⼊美⻝专区，选择⼼仪产品进⾏兑换使⽤。若您遇到权益不到账或权益使⽤有问题，您可拨打客服电话:4001189599，⼯作时间:9:00-21:30。
7、权益须知
(1)本产品为中国移动⽤户专享，在订购及权益发放时请保持号码状态正常，若因销户、停机等因素导致权益⽆法到账，移动公司不予补发。
(2)因权益⽅内部调整，我们可能会变更、终⽌与权益⽅的合作，您可能⽆法继续通过中国移动接受该权益⽅提供产品。届时您已订购的连续包⽉产品可通过后台帮您进⾏退订或退费，同时公司将会及时公告或告知。如因此导致您⽆法使⽤或购买产品的，请您理解我们⽆须对此承担责任，可能给您带来的不便，敬请谅解。
(3)中国移动可能会根据本服务的整体规划，对本服务相关权益细则、收费标准、权益发放⽅式等进⾏修改和变更，前述修改、变更的内容，中国移动将在相应服务⻚⾯公示或以其他合理⽅式进⾏告知，并在告知后⽣效。当您采⽤接受服务的形式(包括但不限于⽀付⾏为、未退定继续使⽤本服务等)，即表明您已经接受本服务的全部修改，以及上述服务细则的修改和变更。
8、本活动内容不构成要约，更多详情请到当地移动营业厅或拨打10086咨询。
        </pre>
    </div>
    <img
      class="iconimg"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/HaiNan/foodCouponMember/bottom.jpg"
    />
    <van-popup
      v-model="showSubmitDialog"
      :close-on-click-overlay="false"
      style="background: none"
    >
      <div class="dialog_wrap">
        <img
          class="dialog-img"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/HaiNan/foodCouponMember/dialog_bg.png"
        />
        <img
          class="cancel"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/HaiNan/foodCouponMember/cancelBtn.png"
          @click="showSubmitDialog = false"
        />
        <img
          class="confirm"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/HaiNan/foodCouponMember/confirm.png"
          @click="handleSubmit"
        />
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getVerificationCode, tradeOrder } from "@/api/qiuMoFusion/index";
import tailJumpMixin from "@/utils/tailJump.js";
import { JuDuoDuo } from "@/utils/common";

export default {
  mixins: [tailJumpMixin],
  components: {},
  data() {
    return {
      yzm_loading: false,
      sendAuthCode: true /*布尔值，通过v-show控制显示‘获取按钮'还是‘倒计时' */,
      auth_time: 0 /*倒计时 计数器*/,
      form: {
        productNo: "84052290",
        phone: "",
        characteristic: "",
        marketUrl: "",
        code: "", //绑定输入验证码框框
        sid: "",
        data: "",
        orderId: "",
      },
      num: 1,
      checked: false,
      showSubmitDialog: false,
      link: "",
    };
  },
  mounted() {
    const that = this;
    const phone = that.form.phone || "";
    window.onpopstate = function (event) {
      // 返回拦截
      JuDuoDuo("100023", phone, "椰岛饭票（19.9元/月）");
    };
  },
  created() {
    const { from = "", phone = "", reasonCode = "" } = this.$route.query;
    const { href = "" } = window.location;
    this.form = {
      ...this.form,
      phone,
      characteristic: from + reasonCode,
      marketUrl: encodeURIComponent(href),
    };
    let state = {
      url: document.URL,
    };
    history.pushState(state, "title", document.URL);
  },
  watch: {
    form: {
      handler(value) {
        const { phone = "", code } = value;
        if (phone.length < 11) {
          this.num = 1;
        } else if (this.sendAuthCode && code.length == 0) {
          this.num = 2;
        } else if (code.length < 6) {
          this.num = 3;
        } else if (code.length == 6 && phone.length == 11) {
          this.num = 4;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async changePhone() {
      let filterPhone = /^1[3456789]\d{9}$/;
      const { phone = "" } = this.form;
      if (!filterPhone.test(phone)) return;
    },
    getAuthCode() {
      var filterPhone = /^1[3456789]\d{9}$/;
      if (!filterPhone.test(this.form.phone)) {
        this.$dialog.alert({ message: "手机号错误" });
      } else {
        if (this.yzm_loading) return;
        this.yzm_loading = true;
        getVerificationCode(
          JSON.stringify({ ...this.form, appPackage: navigator.userAgent })
        )
          .then((res) => {
            this.yzm_loading = false;
            if (res.code !== 0) {
              this.$dialog.alert({ message: res.message || "验证码获取失败" });
              JuDuoDuo("100023", this.form.phone, "椰岛饭票（19.9元/月）");
            } else {
              this.form.data = res.data.data.data;
              this.form.orderId = res.data.data.orderId;
              // 设置倒计时秒
              this.sendAuthCode = false;
              this.auth_time = 120;
              let auth_timetimer = setInterval(() => {
                this.auth_time--;
                if (this.auth_time <= 0) {
                  this.sendAuthCode = true;
                  clearInterval(auth_timetimer);
                }
              }, 1000);
            }
          })
          .catch((error) => {
            this.yzm_loading = false;
          });
      }
    },
    handleSubmit() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      tradeOrder(JSON.stringify(this.form)).then((res) => {
        this.$toast.clear();
        this.dialog = false;
        if (res.code !== 0) {
          this.$toast({
            duration: 8000,
            message: res.message || "提交失败",
          });
          JuDuoDuo("100023", this.form.phone, "椰岛饭票（19.9元/月）");
        } else {
          this.$dialog.alert({
            title: "提交成功",
            message: "具体结果，请以短信提示为准",
            messageAlign: "center",
          });
          JuDuoDuo("100023", this.form.phone, "椰岛饭票（19.9元/月）");
        }
      });
    },
    onSubmit() {
      var filtercode = /^\d{6}$/;
      var filterPhone = /^1[3456789]\d{9}$/;
      if (!this.form.phone) {
        this.$dialog.alert({ message: "请输入手机号" });
      } else if (!filterPhone.test(this.form.phone)) {
        this.$dialog.alert({ message: "手机号错误" });
      } else if (!this.form.code) {
        this.$dialog.alert({ message: "请输入验证码" });
      } else if (!filtercode.test(this.form.code)) {
        this.$dialog.alert({ message: "请输入6位验证码" });
      } else if (!this.checked) {
        this.$toast({
          duration: 8000,
          message: "请阅读并同意相关协议",
        });
      } else {
        this.showSubmitDialog = true;
      }
    },
    showModal1() {
      this.$dialog.alert({
        messageAlign: "left",
        title: "隐私条款",
        message: `《关于客户个人信息收集、使用规则的公告》
  尊敬的客户：
  
         根据《中华人民共和国反恐怖主义法》、《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）和《电话用户真实身份信息登记规定》（工业和信息化部令第25号）等国家法律法规的要求，客户在中国联合网络通信有限公司各类营业网点（含自有营业厅、手机营业厅、网上营业厅、授权合作代理商等）办理固定电话、移动电话（含无线上网卡）入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效证件原件并进行查验、登记，登记信息包括姓名、证件类型、号码及地址等。同时，为更好地提供服务，需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息。客户本人持有效证件可通过自有营业厅查询和/或更正本人信息，或登录手机营业厅查询本人信息。 
  如客户拒绝依法提供个人有效证件及真实信息，我公司有权不提供服务或终止服务。 
  为向客户提供优质、个性化的服务，包括但不限于提供通信服务、保障通信服务安全、改善服务质量、推介个性化产品等，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外泄露客户个人信息： 
  (a)事先获得客户的明确授权； 
  (b)根据有关的法律法规要求； 
  (c)按照相关司法机关和/或政府主管部门的要求； 
  (d)为维护社会公众的利益所必需且适当； 
  (e)为维护我公司或客户的合法权益所必需且适当。`,
      });
    },
    showModal2() {
      this.$dialog.alert({
        messageAlign: "left",
        title: "受理协议",
        message: `1、产品资费19.9元/⽉(订购当⽇⼀次性扣除，续订⽉按⾃然⽉份续订，在每⽉初进⾏扣费);

2、产品使⽤

（1）订购后每⽉可享受1张20元饭票券、权益四选⼀(爱奇艺⻩⾦会员⽉卡/优酷VIP会员⽉卡/腾讯视频VIP⽉卡/20元饭票券*1张)、10GB/⽉视频、⾳乐类共享定向流量(抖⾳、快⼿、腾讯视频、爱奇艺、优酷、咪咕视频、QQ⾳乐、酷狗⾳乐、咪咕⾳乐、喜⻢拉雅FM)，订购后⽴即⽣效，次⽉⾃动续订，权益⽆需客户领取，⾃动发放⾄账户中

（2）若⽤户当⽉退订海南移动饭票联名会员，会员将在次⽉⽣效;且赠送的定向流量也将在次⽉失效。

3、定向流量说明

（1）定向流量优先于套餐通⽤流量;使⽤定向流量时优先使⽤本次产品的定向流量，单系定向流量的使⽤优先于多系共享的定向流量产品。

（2）本产品定向流量不结转、不能与他⼈共享、不能转赠，不可回溯办理前已使⽤流量。

（3）定向流量适⽤范围:建议使⽤定向流量包前将APP升级⾄最新版本，请在WIFI功能关闭下使⽤定向流量功能，避免WIFI和移动⽹络频繁切换导致定向流量服务启动失败。客户端不包含在“定向流量“内的适⽤范围，特别说明如下:

①客户端启动、登录;客户端内的插播⼴告(图⽚、⽂字及视频)、内嵌H5⻚⾯、弹幕，第三⽅的⼴告，直播或主播类视频，K歌功能，语⾳及视频聊天，在线观看和下载缓存的第三⽅视频，下载视频等。

②⼿机号码处于国际或港澳台地区漫游状态下产⽣的流量。

③利⽤⼿机设置代理服务器或VPN等⽅式，访问APP所产⽣的流量。

④⽤⽆线上⽹卡、平板电脑、CPE、MIFI等⾮⼿机设备使⽤产⽣的流量。

⑤使⽤CMWAP接⼊点⽅式，访问视频APP所产⽣的流量。

⑥当号码作为热点使⽤时，⽹络不稳定可能会出现定向流量和通⽤流量交错使⽤的情况。

（4）产品包含的定向流量仍遵循客户基础套餐的封顶、限速规则，详询10086。

4、权益发放与领取

（1）发放时间:产品⽣效期间，每⾃然⽉1⽇将⾃动发放1张20元饭票券⾄客户账户中，过期未使⽤导致失效则次⽉不做补发。

（2）发放权益:1张20元饭票券。

（3）领取时间:产品⽣效期间，每⾃然⽉1⽇⾄末⽇起均可领取权益四选⼀内容。

（4）领取权益:爱奇艺⻩⾦会员⽉卡/优酷VIP会员⽉卡/腾讯视频VIP⽉卡/20元饭票券*1张(四选⼀)。

（5）有效期是⽣效⽇期⾄次⽉同⽇，例如:3⽉5⽇⽣效的⽉代⾦券，有效期⾄4⽉4⽇。

5、查看⼊⼝:

（1）20元饭票券：每⽉⾃动下发，到账后30天内（含当⽇）有效，需使⽤订购⼿机号码登录【和圈圈-微信公众号-圈圈中⼼-权益记录】查看权益。

（2）爱奇艺⻩⾦会员⽉卡/优酷VIP会员⽉卡/腾讯视频VIP⽉卡:权益领取成功后，官⽅直充到订购的⼿机号中(⽆法更改充值账户)，预计1-5分钟内⾃动到账。请⽤会员本⼈⼿机号码登录对应APP查看到账情况，具体使⽤有效期以官⽅展示为准。

6、使⽤说明:使⽤⽅式:点击饭票券-⽴即兑换-权益领取成功后，饭票将⾃动发放⾄同⼀⼿机号所在的账户中;权益四选⼀的视频会员在活动⻚⾯点击领取后，将充值到对应⼿机号中，下载对应APP即可查看使⽤。特殊说明:权益领取后⽆法退换，饭票不找零，不兑现有效期⾃领取之⽇起30*24⼩时内有效，逾期视为⾃动放弃权益。⽤户可进⼊美⻝专区，选择⼼仪产品进⾏兑换使⽤。若您遇到权益不到账或权益使⽤有问题，您可拨打客服电话:4001189599，⼯作时间:9:00-21:30。

7、权益须知

(1)本产品为中国移动⽤户专享，在订购及权益发放时请保持号码状态正常，若因销户、停机等因素导致权益⽆法到账，移动公司不予补发。

(2)因权益⽅内部调整，我们可能会变更、终⽌与权益⽅的合作，您可能⽆法继续通过中国移动接受该权益⽅提供产品。届时您已订购的连续包⽉产品可通过后台帮您进⾏退订或退费，同时公司将会及时公告或告知。如因此导致您⽆法使⽤或购买产品的，请您理解我们⽆须对此承担责任，可能给您带来的不便，敬请谅解。

(3)中国移动可能会根据本服务的整体规划，对本服务相关权益细则、收费标准、权益发放⽅式等进⾏修改和变更，前述修改、变更的内容，中国移动将在相应服务⻚⾯公示或以其他合理⽅式进⾏告知，并在告知后⽣效。当您采⽤接受服务的形式(包括但不限于⽀付⾏为、未退定继续使⽤本服务等)，即表明您已经接受本服务的全部修改，以及上述服务细则的修改和变更。

8、本活动内容不构成要约，更多详情请到当地移动营业厅或拨打10086咨询。`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.enjoy {
  background-color: rgb(214, 30, 30);

  .iconimg {
    width: 100%;
  }
  .form {
    position: relative;
    .one,
    .two,
    .three,
    .four {
      width: 326px;
      position: absolute;
      z-index: 1;
      pointer-events: none;
    }
    .one {
      transform: translate(80px, -80px);
    }

    .two {
      width: 166px;
      right: 40px;
      top: 50px;
    }

    .three {
      width: 456px;
      transform: translate(80px, -20px);
    }

    .four {
      width: 310px;
      transform: translate(300px, 300px);
    }

    ::v-deep .van-cell {
      width: 680px;
      height: 100px;
      background-color: #ffffff;
      border-radius: 50px;
      margin: 90px auto 0;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 0 0 46px;

      &.input-code {
        margin-top: 45px;

        .code {
          width: 229px;
          height: 100px;
          text-align: center;
          line-height: 100px;
          color: #fff;
          font-size: 30px;
          font-weight: bold !important;
          font-weight: normal;
          background: #ff850f;
        }
      }

      .van-field__body {
        height: 100%;
      }

      &::after {
        border: none;
      }

      .van-field__control {
        font-size: 36px;
        &::-webkit-input-placeholder {
          font-size: 36px;
          font-weight: 400;
          color: #969696;
        }
      }
    }

    .submit {
      width: 660px;
      display: block;
      margin: 50px auto 0;
      animation: identifier 1.2s infinite;

      @keyframes identifier {
        0% {
          transform: scale(0.9);
        }
        50% {
          transform: scale(1);
        }
        100% {
          transform: scale(0.9);
        }
      }
      &.none {
        pointer-events: none;
      }

      &.bottom {
        position: fixed;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 634px;
        bottom: 70px;
      }
    }
    .checked {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 24px;
      font-weight: 400;
      margin-top: 40px;

      ::v-deep .van-checkbox {
        width: auto;
      }
      .checked-label {
        color: #fff !important;
      }
      .book {
        font-weight: 400;
        color: #f8f107;
      }
      .kefu {
        color: #fff;
        font-size: 28px;
        margin: 16px 0;
      }
    }
  }

  .info {
    font-size: 24px;
    color: #000;
    font-weight: 400;
    padding: 0px 28px 0px;
    .info-title {
      margin: 50px auto 40px;
      text-align: center;
      font-size: 32px;
      font-weight: 700;
    }

    .info-text {
      white-space: pre-wrap;
      font-size: 30px;
      line-height: 1.3;
      span {
        color: #fff;
      }
    }
  }

  .dialog_wrap {
    width: 720px;
    height: 707px;
    position: relative;
    .dialog-img {
      width: 100%;
      height: 100%;
    }
    .cancel {
      position: absolute;
      left: 120px;
      bottom: 40px;
      width: 228px;
      height: 84px;
    }
    .confirm {
      position: absolute;
      right: 120px;
      bottom: 40px;
      width: 228px;
      height: 84px;
    }
  }
}
</style>
